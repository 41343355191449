<template>
  <div class="bg-primary-100 py-25 lg:py-30 xl:py-40 paragraph--padded">
    <div class="container">
      <Accordion
        v-for="faq in faqs"
        :key="faq.frage"
        :title="faq.frage"
        class="mb-10 break-words"
      >
        <div v-html="formatAnswer(faq.antwort)" />
        <p class="mt-20">
          Link:
          <a
            v-if="faq.link && faq.link.length > 0"
            :href="faq.link[0]"
            target="_blank"
            class="link"
          >
            {{ faq.link_anzeigetext }}
          </a>
          <br />
          Kontakt:
          <a
            v-if="faq.kontakt && faq.kontakt.length > 0"
            :href="`mailto:${faq.kontakt[0]}`"
            class="link"
          >
            {{ faq.kontakt[0] }}
          </a>
          <br />
          Zuletzt aktualisiert: {{ formatDate(faq.zuletzt_aktualisiert) }}
        </p>
      </Accordion>
      <p v-if="error">{{ error }}</p>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted } from 'vue'
import type { Faq } from '~/layers/eurovision-faq/types'
import { marked } from 'marked'

defineBlokkliFragment({
  name: 'eurovision_faq',
  label: 'Eurovision FAQ',
})

const faqs = ref<Faq[]>([])
const error = ref<string | null>(null)

const fetchData = async () => {
  try {
    const response = await fetch('/api/ogd/esc-faq')

    if (!response.ok) {
      error.value = `Failed to fetch data: ${response.statusText}`
      return
    }
    const data = await response.json()
    faqs.value = data.data.results
  } catch (e) {
    const err = e as Error
    error.value = err.message
  }
}

onMounted(() => {
  fetchData()
})

const formatDate = (dateString: string): string => {
  const date = new Date(dateString)
  return new Intl.DateTimeFormat('de-CH', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  }).format(date)
}

const formatAnswer = (text: string): string => {
  return marked(text) as string
}
</script>
