import type { BlockOptionDefinition } from '#blokkli/types/blokkOptions'

type GlobalOptionsDefaults = {
  type: BlockOptionDefinition['type']
  default: any
}

export const bundlesWithVisibleLanguage: string[] = ["focus_teaser","audio_player","button","cleverreach_form","dynamic_teaser_list","event_list","expand_section","external_contact","csd_service","gallery","government_contact","iframe","image","information_link_attachment","link_list","logo_pictogramm","mapbs_iframe","next_best_action","place","popular_links","publication_list","section_title","quote","table","teaser","teaser_list","text","video_remote"]
export const bundlesWithHiddenGlobally: string[] = []

export const globalOptionsDefaults: Record<string, GlobalOptionsDefaults> = {
  "bkVisibleLanguages": {
    "default": [],
    "type": "checkboxes"
  },
  "teaserStyle": {
    "default": "normal",
    "type": "radios"
  },
  "showInToc": {
    "default": true,
    "type": "checkbox"
  }
} as const