<template>
  <div v-if="text || isEditing" class="email-text">
    <EmailFrameContentSection padding-top="0" padding-bottom="0">
      <mj-column width="100%" padding="0">
        <EmailText>
          <div
            v-if="isEditing"
            v-blokkli-editable:paragraphs_text
            v-html="stylifiedText"
          />

          <div v-else v-html="stylifiedText" />
        </EmailText>
      </mj-column>
    </EmailFrameContentSection>
  </div>
</template>

<script lang="ts" setup>
const { isEditing } = defineBlokkli({
  bundle: 'text',
  editor: {
    editTitle: (el) => el.innerText,
    getDraggableElement: (el) => el.querySelector('.email-text'),
  },
  renderFor: [
    {
      fieldList: 'email',
    },
  ],
})

const props = defineProps<{
  text?: string
}>()

function getMarginStyle(
  top: string,
  right: string,
  bottom: string,
  left: string,
) {
  return `padding: 0; margin-top: ${top}; margin-right: ${right}; margin-bottom: ${bottom}; margin-left: ${left};`
}

const stylifiedText = computed(() => {
  if (!props.text) {
    return ''
  }

  return (
    props.text
      .replace(/<p/g, `<p style="${getMarginStyle('25px', '0', '15px', '0')}"`)
      .replace(
        /<h3/g,
        `<h3 style="${getMarginStyle('25px', '0', '15px', '0')} font-weight: bold; font-size: 20px;"`,
      )
      .replace(/<ul/g, `<ul style="${getMarginStyle('25px', '0', '0', '0')}"`)
      .replace(/<ol/g, `<ol style="${getMarginStyle('25px', '0', '0', '0')}"`)
      .replace(
        /<li>\s+<ol/g,
        `<li><ol style="${getMarginStyle('10px', '0', '0', '0')}"`,
      )
      .replace(
        /<li>\s+<ul/g,
        `<li><ul style="${getMarginStyle('10px', '0', '0', '0')}"`,
      )
      .replace(
        /<li/g,
        `<li style="${getMarginStyle('0', '0', '20px', '25px')}"`,
      )
      .replace(/<a/g, '<a style="color: #1E4557;"')
      .replace(/<strong>/g, '<b style="font-weight: bold;">')
      .replace(/<\/strong>/g, '</b>')
      .replace(/<em>/g, '<i style="font-style: italic;">')
      .replace(/<\/em>/g, '</i>')
      .replace(/<u>/g, '<u style="text-decoration: underline;">')
      // Gets rid of the very first margin top of the block.
      .replace(
        /^<([a-z]+) style="padding: 0; margin-top: \d+px; /g,
        '<$1 style="padding: 0; margin-top: 0; "',
      )
  )
})

defineOptions({
  name: 'ParagraphEmailText',
})
</script>
