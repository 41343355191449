<template>
  <div class="container paragraph--margin">
    <p>Kontingente für {{ placeLabel }}:</p>
    <BSSelect
      id="year"
      v-model="selectedYear"
      avoid-placeholder
      class="my-10 w-100"
    >
      <option v-for="year in years" :key="year" :value="year">
        {{ year }}
      </option>
    </BSSelect>
    <StructuredTable
      v-if="data"
      v-bind="data"
      :caption="
        $texts(
          'listOfEventPlaceContingents',
          'Liste von Kontingenten für Events',
        )
      "
    />
  </div>
</template>

<script lang="ts" setup>
import type { ParagraphEventPlaceContingentFragment } from '#graphql-operations'

defineBlokkli({
  bundle: 'event_place_contingent',
})

const props = defineProps<{
  eventPlace: ParagraphEventPlaceContingentFragment['eventPlace']
}>()
const placeLabel = computed(() => props.eventPlace?.label)

defineOptions({
  name: 'ParagraphEventPlaceContingent',
})

// build the select options with the x last years
const currentYear: number = new Date().getFullYear()
const years = Array.from({ length: 4 }).map((_, i) => String(currentYear - i))
const selectedYear = ref<string>(years[0])

// define the query parameters for the API request
const query = computed(() => ({
  streetId: props.eventPlace?.fieldId,
  year: selectedYear.value,
}))

// fetching and formatting the data each time one of the value of "query" is updated
const { data } = await useFetch('/api/search/contingents', {
  query,
  transform: function (data) {
    // console.log('fetching data for ' + selectedYear.value)
    return defineStructuredTable(
      ['Veranstaltungen', 'Kontingent', 'beansprucht'],
      (data?.contingents || []).map((item) => {
        return [item.usageDescription, item.maxUsage, item.currentUsage || '0']
      }),
    )
  },
})
</script>
