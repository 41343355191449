<template>
  <RenderWithDependency
    :class="{
      'container paragraph--margin': fieldListType !== 'box',
      'not-first:mt-30': fieldListType === 'box',
    }"
    :dependency="name || role || phoneNumber?.first || mail || additionalInfo"
  >
    <h3 v-if="name" v-blokkli-editable:field_name class="font-bold">
      {{ name }}
    </h3>
    <div v-if="role" v-blokkli-editable:field_role>{{ role }}</div>
    <div v-if="phoneNumber?.first || mail" class="flex gap-10 mt-15">
      <DrupalPhoneNumber
        v-if="phoneNumber?.first"
        v-bind="phoneNumber.first"
        class="button is-action is-link"
      />

      <a v-if="mail" :href="'mailto:' + mail" class="button is-action is-link">
        {{ mail }}
      </a>
    </div>

    <div v-if="additionalInfo" class="whitespace-pre-wrap mt-15">
      {{ additionalInfo }}
    </div>
  </RenderWithDependency>
</template>

<script lang="ts" setup>
import type { ParagraphExternalContactFragment } from '#graphql-operations'

const { fieldListType } = defineBlokkli({
  bundle: 'external_contact',
  globalOptions: ['bkVisibleLanguages'],
  editor: {
    editTitle: (el) =>
      // @ts-ignore
      el.querySelector('div.font-bold')?.innerText,
  },
})

defineProps<{
  name?: string
  role?: string
  additionalInfo?: string
  mail?: string
  phoneNumber?: ParagraphExternalContactFragment['phoneNumber']
}>()

defineOptions({
  name: 'ParagraphExternalContact',
})
</script>
