<template>
  <div class="relative">
    <select
      :id="id"
      v-model="modelValue"
      class="input select peer"
      :disabled="disabled"
      :avoidPlaceholder="avoidPlaceholder"
    >
      <option v-if="!avoidPlaceholder" value="">
        {{ placeholder || $texts('select.placeholder', 'Bitte auswählen') }}
      </option>
      <option v-if="!avoidPlaceholder" value="-" disabled>
        ————————————————————
      </option>
      <slot>
        <template v-if="options">
          <option
            v-for="option in options"
            :key="option.value"
            :value="option.value"
          >
            {{ option.label || option.value }}
          </option>
        </template>
      </slot>
    </select>

    <SpriteSymbol
      name="caret"
      class="absolute top-1/2 -translate-y-1/2 right-15 w-10 h-6 text-blue-700"
      aria-hidden="true"
    />
  </div>
</template>

<!--
Behaves like a standard select (because it is), but also offers a `placeholder` attribute.

Usage:

  <BSSelect placeholder="Please select" v-model="v">
    <option value="1">Foobar</option>
    <option value="2">Lorem ipsum dolor sit amet</option>
  </BSSelect>

  {{ v }}
-->

<script lang="ts" setup>
export type SelectOption = {
  value: string
  label?: string
}

defineProps<{
  id?: string
  disabled?: boolean
  avoidPlaceholder?: boolean
  placeholder?: string
  options?: SelectOption[]
}>()

const modelValue = defineModel<string>({ default: '' })
</script>

<style lang="postcss">
.select {
  @apply appearance-none w-full outline-none relative font-medium;
  @apply transition-all ease-in-out duration-250;
  @apply hover:border-blue-500;
}
</style>
