<template>
  <div>
    <TeaserDefault
      v-if="url && title"
      :to="url"
      :title="title"
      :text="text"
      :media="media"
      :square="paragraphTeaserStyle === 'reduced'"
      :is-external="isExternal"
      :to-node-bundle="toNodeBundle"
    />
    <div v-else-if="isEditing" class="bg-red-200 text-red-700 p-10">
      {{
        $texts(
          'teaser.teaserNotTranslated',
          'Dieser Teaser wurde noch nicht übersetzt.',
        )
      }}
    </div>
  </div>
</template>

<script lang="ts" setup>
/**
 * This paragraph has a link field that allowed both internal and external links.
 * In case of external links, the title, text and media fields are required. For
 * internal links, they are optional.
 *
 * The title, text and media fields should always be used as the primary source.
 * If the link is internal we can fallback to the linked entity's teaser data.
 */
import type {
  MediaImageFragment,
  ParagraphTeaserEntityFragment,
  ParagraphTeaserFragment,
} from '#graphql-operations'
import type { NodeBundle } from '~/types/drupal'

const { isEditing } = defineBlokkli({
  bundle: 'teaser',
  globalOptions: ['bkVisibleLanguages'],
  editor: {
    editTitle: (el) => el.querySelector('h3')?.innerText,
    mockProps: (text) => {
      return {
        overrideTitle: text || 'Teaser Titel',
        link: {
          uri: {
            path: '/',
          },
        },
      }
    },
  },
})

const props = defineProps<{
  link?: ParagraphTeaserFragment['link']
  overrideTitle?: ParagraphTeaserFragment['overrideTitle']
  overrideText?: ParagraphTeaserFragment['overrideText']
  overrideMedia?: ParagraphTeaserFragment['overrideMedia']
}>()

const paragraphTeaserStyle = inject<ComputedRef | null>(
  'paragraphTeaserStyle',
  null,
)

const { $texts } = useNuxtApp()

/**
 * If the link is internal, this contains the entity that is being linked.
 */
const entity = computed<ParagraphTeaserEntityFragment | undefined>(() => {
  const url = props.link?.uri
  if (url && 'entity' in url) {
    return url.entity
  }
  return undefined
})

const isExternal = computed(() => {
  if (props.overrideTitle && props.overrideText && props.overrideMedia) {
    return 'isExternal'
  }

  return 'isInternal'
})

const title = computed(() => {
  if (props.overrideTitle) return props.overrideTitle
  if (entity.value && 'teaserTitle' in entity.value) {
    return entity.value.teaserTitle
  }
  return ''
})

const text = computed(() => {
  if (props.overrideText) return props.overrideText
  if (entity.value && 'teaserText' in entity.value) {
    return entity.value.teaserText
  }
  return ''
})

const media = computed<MediaImageFragment | undefined>(() => {
  // If the teaser style is without images, don't show any media.
  if (paragraphTeaserStyle && paragraphTeaserStyle.value === 'withoutImages') {
    return undefined
  }

  // Use media override if given.
  if (props.overrideMedia) {
    return props.overrideMedia
  }

  if (entity.value && 'teaserImage' in entity.value) {
    return entity.value.teaserImage
  }
  return undefined
})

const url = computed(() => {
  return props.link?.uri?.path || ''
})

const toNodeBundle = computed<NodeBundle | undefined>(() => {
  // Unknown which bundle this teaser links to, so the teaser should behave generically.
  if (!entity.value) {
    return undefined
  }

  return entity.value.entityBundle as NodeBundle
})

defineOptions({
  name: 'ParagraphTeaser',
})
</script>
